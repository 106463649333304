<template>
  <div class="container flex">
    <div class="w-0 lg:py-14 lg:ml-4 lg:w-60">
      <ul class="relative">
        <li class="relative">
          <router-link to="/privacy-policy" class="flex text-base lg:py-2 h-12 overflow-hidden whitespace-nowrap">{{ contents[activeIndex].sideBarComp[0] }}</router-link>
        </li>
        <li class="relative">
          <router-link to="/terms-conditions" class="flex items-center text-base font-bold text-yellow lg:py-2 h-12 overflow-hidden whitespace-nowrap">{{
            contents[activeIndex].sideBarComp[1]
          }}</router-link>
        </li>
      </ul>
    </div>
    <div class="privacy_policy mx-4">
      <div class="container py-16 mdmax:py-10 text-justify mdmax:text-left">
        <div class="flex mdmax:flex-col justify-between mdmax:mb-4 mb-7">
          <div class="w-3/4 mdmax:w-full">
            <router-link to="/" class="text-base font-normal text-yellow cursor-pointer">
              <span class="pr-1">
                <Chevron direction="left" width="10" height="10" color="#F7931E" />
              </span>
              {{ contents[activeIndex].backToHome }}
            </router-link>
            <div class="w-full hidden mdmax:pt-3 mdmax:inline-block">
              <div class="text-xs font-normal text-neutral-500 mb-2">{{ contents[activeIndex].selections.selectLang }}</div>
              <div class="relative">
                <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-10">
                  <Chevron direction="down" width="16" height="16" color="#F7931E" />
                </span>
                <select
                  v-model="activeIndex"
                  class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full h-10 text-xs rounded-lg appearance-none bg-white relative z-1"
                >
                  <option :value="index" v-for="(item, index) in language" :key="'language1-' + index">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
            <div class="text-3.5xl mdmax:text-2xl font-bold text-neutral mdmax:pt-4 pt-6">{{ contents[activeIndex].staticText.title }}</div>
          </div>
          <div class="w-1/4 mdmax:hidden">
            <div class="text-xs font-normal text-neutral-500 mb-2">{{ contents[activeIndex].selections.selectLang }}</div>
            <div class="relative w-full">
              <span class="inline-block absolute right-5 top-1/2 transform -translate-y-1/2 leading-0 z-10">
                <Chevron direction="down" width="16" height="16" color="#F7931E" />
              </span>
              <select v-model="activeIndex" class="border border-grey-field pl-3 py-3 focus:outline-none focus:ring focus:ring-tosca w-full rounded-lg appearance-none bg-white relative z-1">
                <option :value="index" v-for="(item, index) in language" :key="'language2-' + index">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="pb-4" v-for="(content, index) in contents[activeIndex].loopingText" :key="'loopingText-' + index">
          <div class="text-lg mdmax:text-base font-semibold text-neutral-500 pb-4">{{ content.title }}</div>
          <div class="text-base mdmax:text-sm font-normal text-neutral-500 pb-4" v-for="(text1, index) in content.body1" :key="'body1-' + index">
            <div v-if="typeof text1 === 'object'" class="px-4">
              <ul class="dashed">
                <li v-for="(text2, index) in text1.body2" :key="'body2-' + index">
                  {{ text2 }}
                </li>
              </ul>
            </div>
            <div v-else>
              <div v-html="text1"></div>
            </div>
          </div>
          <div class="text-base mdmax:text-sm font-normal text-neutral-500 pt-3" v-if="content.body2">
            {{ content.body2 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'terms-conditions',
  components: {
    Chevron: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Chevron')
  },
  data: () => ({
    activeIndex: 0,
    language: ['Bahasa Indonesia', 'English'],
    // selections1: ['Pilih Bahasa', 'Select Language'],
    // sideBarComp1: [{ side1: 'Syarat & Ketentuan', side2: 'Kebijakan Privasi' }, { side1: 'Terms & Condition', side2: 'Privacy Policy' }],
    contents: [
      {
        staticText: {
          title: 'SYARAT & KETENTUAN'
        },
        selections: {
          selectLang: 'Pilih Bahasa'
        },
        sideBarComp: ['Kebijakan Privasi', 'Syarat & Ketentuan'],
        backToHome: 'Kembali ke Beranda',
        loopingText: [
          {
            title: 'A. KETENTUAN UMUM',
            body1: [
              'Syarat & Ketentuan ini merupakan perjanjian antara pengguna (“Anda”) dan PT Generasi Teknologi Buana (“Kami”), sebuah perseroan terbatas yang didirikan dan beroperasi secara sah berdasarkan hukum Negara Republik Indonesia dan berdomisili di DKI Jakarta. Syarat & Ketentuan ini untuk mengatur Anda saat mengakses dan menggunakan aplikasi, situs web yang Kami kelola, fitur, teknologi, konten dan produk yang Kami sediakan (“Platform”), termasuk namun tidak terbatas atas semua turunan dan/atau yang berhubungan atau berkaitan satu dengan yang lain atas aplikasi, situs web, fitur, teknologi, konten dan produk Kami.',
              'Dengan menyetujui Syarat & Ketentuan ini, Anda juga menyetujui Syarat & Ketentuan tambahan, dan perubahannya atau turunannya dan/atau yang berhubungan atau berkaitan dengan Kami yang merupakan bagian yang tidak terpisahkan dari Syarat & Ketentuan ini (“Syarat & Ketentuan”).',
              'Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18 (delapan belas) tahun atau sudah menikah dan tidak berada di bawah perwalian atau pengampuan dan Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Syarat & Ketentuan ini. Jika Anda tidak memenuhi ketentuan tersebut, maka tindakan Anda mendaftar, mengakses, menggunakan, atau melakukan aktivitas lain dalam Aplikasi dan/atau Layanan yang Kami sediakan harus dalam sepengetahuan, pengawasan dan persetujuan yang sah dari orang tua, wali atau pengampu Anda. Orang tua, wali atau pengampu yang memberikan persetujuan bagi Anda yang berusia di bawah 18 (delapan belas) tahun bertanggung jawab secara penuh atas seluruh tindakan dan aktivitas Anda dalam penggunaan dan akses terhadap Platform Kami.',
              'Anda secara tegas mengesampingkan setiap hak berdasarkan hukum untuk membatalkan atau mencabut setiap dan seluruh persetujuan yang Anda berikan berdasarkan Syarat & Ketentuan ini pada waktu Anda dianggap telah dewasa secara hukum.',
              'Dengan mendaftar dan/atau menggunakan Aplikasi Kami, maka Anda dan/atau orang tua, wali atau pengampu Anda (jika Anda berusia di bawah 18 tahun) dianggap telah membaca, mengerti, memahami, dan menyetujui semua isi dalam Syarat & Ketentuan ini. Jika Anda tidak menyetujui salah satu, sebagian, atau seluruh Syarat & Ketentuan ini, maka mohon untuk segera menghentikan akses dan penggunaan Anda terhadap Platform Kami.'
            ]
          },
          {
            title: 'B. PEMBUATAN AKUN',
            body1: [
              'Sebelum Anda menggunakan Platform Kami, Anda dianggap menyetujui Syarat & Ketentuan ini dan Kebijakan Privasi, dan mendaftarkan diri Anda pada Platform kami dengan memberikan informasi yang kami perlukan. Saat melakukan pendaftaran kami akan meminta Anda memberikan nama lengkap, alat nomor telepon dan surat elktronik, apabila Kami mengetahui data-data yang diberikan terbukti tidak benar maka Kami dapat menghentikan Anda untuk pengguaan Platform Kami.',
              'Dengan data yang telah Anda berikan, sistem Kami akan membuatkan akun pada Platform (“akun”) yang digunakan untuk pengunaan platform dan memesan layanan yang disediakan di dalam Platform. Ketika Anda keluar dari akun Anda maka Anda perlu memasukkan alamat surat elektronik yang Anda berikan kepada Kami saat melakukan pendaftaran dan memasukan kata sandi (password).',
              'Akun tidak boleh dialihkan dengan alasan apapun kepada orang lain hanya dapat digunakan oleh Anda. Jika Kami mengetahui bahwa akun telah dialihkan atau digunakan orang lain maka Kami berhak menolak untuk memfasilitasi layanan. ',
              'Keamanan dan kerahasiaan akun Anda sepenuhnya merupakan tanggung jawab pribadi Anda. Segala kerugian dan risiko yang ada akibat kelalaian Anda dalam menjaga keamanan dan kerahasiaan sebagaimana disebutkan ditanggung oleh Anda. Dengan demikian, Kami akan menganggap setiap penggunaan atau pesanan yang dilakukan melalui akun Anda sebagai permintaan yang sah dari Anda. ',
              'Anda harap segera memberitahukan kepada Kami jika Anda mengetahui atau menduga bahwa akun Anda telah digunakan tanpa sepengetahuan dan persetujuan Anda. Kami akan melakukan tindakan yang Kami anggap perlu dan dapat Kami lakukan terhadap penggunaan tanpa persetujuan tersebut.',
              'Jika Anda telah memiliki akun di Platform Kami, maka akun tersebut akan dikelolah oleh Kami dan G2Academy (Afiliasi) dan Akun tersebut dapat digunakan Pada Platform G2Academy (akun ditautkan).',
              '<b>G2Academy</b> adalah mitra atau afiliasi dari  Eduverse yang saling bekerjasama, dimana G2Academy adalah platform digital resmi dari program Kartu Prakerja yang menyediakan layanan pembelian kursus online dan offline.'
            ]
          },
          {
            title: 'C. INFORMASI PRIBADI',
            body1: [
              'Pengumpulan, penggunaan, penyimpanan, pengolahan, dan pembagian Informasi Pribadi atau data pribadi Anda, seperti nama, alamat, nomor telepon dan surat elektronik, Anda yang Anda berikan ketika Anda melakukan pembuatan akun tunduk pada Kebijakan Privasi, yang merupakan bagian yang tidak terpisahkan dari Syarat & Ketentuan ini. '
            ]
          },
          {
            title: 'D. AKSES PLATFORM ',
            body1: [
              'Kami tidak menjamin bahwa Platform Kami, maupun konten di dalamnya, akan selalu tersedia atau tanpa gangguan. Izin untuk mengakses platform Kami bersifat sementara. Kami dapat menangguhkan, menarik, memberhentikan, maupun mengganti bagian mana pun dari Platform Kami tanpa pemberitahuan sebelumnya. Kami tidak bertanggung jawab atas alasan apa pun yang membuat platform Kami tidak tersedia pada waktu atau periode tertentu dan dari waktu ke waktu. Anda bertanggung jawab untuk membuat semua pengaturan yang dibutuhkan untuk mendapatkan akses terhadap Platform Kami.'
            ]
          },
          {
            title: 'E. PEMBELIAN DAN HAK AKSES TERHADAP KELAS PADA PLATFORM',
            body1: [
              'Pada saat Anda melakukan pembelian atas kelas pelatihan yang terdapat pada Platform Kami, maka Kami memberi Anda tidak eksklusif, lisensi terbatas, dan tidak dapat dipindahtangankan untuk mengakses dan melihat materi dan konten terkait kelas yang telah Anda bayarkan. Materi dan konten kelas yang didapat setelah Anda melakukan pembelian kelas pelatihan hanyalah untuk pengunaan kepentingan pribadi dan semua pengunaan untuk tujuan lain secara tegas dilarang (seperti  tujuan komersial). ',
              'Anda tidak boleh merekam, mendistribusikan kembali, menjual, mengirimkan, menetapkan, menyiarkan, memproduksi ulang, membagi, meminjamkan, memodifikasi, mengadaptasi, mengedit, sublisensi, membuat karya turunan,  atau mentransfer atau menggunakan materi dan konten pada Platform kecuali atas persetujuan tertulis sebelumnya dari Kami. ',
              'Kami memberikan lisensi akses kepada Anda Ketika pembelian kelas pada Platform Kami selama Platform ini masih beroperasi kepada Anda. Akan tetapi, Kami berhak untuk mencabut lisensi apa pun untuk mengakses dan menggunakan kelas sewaktu-waktu dalam hal Kami memutuskan atau wajib menonaktifkan akses terhadap kelas karena alasan tertentu (seperti pelanggaran kekayaan intelektual) dan Kelas pelatihan yang telah Anda beli melalui Platform Kami tidak dapat dibatalkan atau diuangkan Kembali.'
            ]
          },
          {
            title: 'F. SERTIFIKAT',
            body1: [
              'Kami akan menerbitkan sertifikat pada Platform Kami dan untuk memperoleh Sertifikat Anda harus menyelesaikan seluruh rangkaian kewajiban (video) dan harus mengikuti ujian akhir dan lulus ujian tersebut di atas passing grade yang sudah ditentukan. ',
              'Sertifikat tersebut merupakan bentuk penghargaan yang Kami berikan apabila Anda mengikuti seluruh rangkaian pelatihan dengan menyeluruh dan sebagai tanda bahwa Anda telah menguasai materi yang diberikan dalam kelas tersebut. '
            ]
          },
          {
            title: 'G. KETENTUAN PEMBAYARAN',
            body1: [
              'Pada saat Anda melakukan pembayaran atas kelas pada Platform Kami, maka Anda setuju untuk:',
              {
                body2: ['menggunakan alat pembayaran yang sah dengan metode yang tersedia pada Platform; dan', 'membayar harga pembelian akses terhadap kelas yang Anda beli.']
              },
              'Anda memberi wewenang kepada Kami untuk melakukan proses transaksi jual beli atas kelas yang Anda beli dengan kartu debit, kartu kredit atau alat pembayaran lainnya yang Anda gunakan pada saat pembelian kelas tersebut di Platform Kami. ',
              'Kami bekerja sama dengan pihak ketiga terkait proses pembayaran untuk menawarkan kepada Anda metode pembayaran yang paling nyaman dan untuk menjaga keamanan informasi pembayaran Anda. Pada saat Anda melakukan pembelian, Anda setuju untuk tidak menggunakan metode pembayaran yang tidak valid atau tidak sah. Jika metode pembayaran Anda gagal, mohon untuk dapat segera memberitahukan kepada Kami melalui detail kontak yang tercantum pada bagian Syarat & Ketentuan ini. Kami berhak menonaktifkan akses Anda terhadap kelas yang Anda beli apabila Kami belum menerima pembayaran atas kelas yang Anda beli tersebut. '
            ]
          },
          {
            title: 'H. PENGUNAAN YANG DILARANG',
            body1: [
              'Platform kami dapat digunakan untuk tujuan yang diperbolehkan menurut peraturan perundang-undangan yang berlaku. Anda dilarang menggunakan Platform Kami jika:',
              {
                body2: [
                  'melanggar hukum dan peraturan lokal, nasional, maupun internasional yang berlaku;',
                  'menipu, atau memiliki tujuan atau dampak yang melanggar hukum atau menipu;',
                  'tujuan membahayakan atau mencoba mencelakakan anak di bawah umur dengan cara apa pun;',
                  'mengirim, secara sadar menerima, mengunggah, mengunduh, menggunakan, atau menggunakan kembali materi yang tidak sesuai dengan standar konten Kami;',
                  'mengakses tanpa izin, mengganggu, merusak, atau mengacak-acak apapun berkaitan dengan Platform Kami;',
                  'menyebarkan atau mengirimkan materi iklan atau promosi yang tidak. diinginkan atau tidak sah, serta bentuk permintaan serupa lainnya (seperti spam); dan',
                  'sengaja meneruskan data, mengirim atau mengunggah materi yang mengandung virus, trojan, worm, logic bomb, keystroke loggers, spyware, adware, malware maupun program berbahaya lainnya atau kode komputer sejenis yang dirancang untuk memberikan efek merugikan terhadap pengoperasian perangkat lunak atau perangkat keras apa pun. '
                ]
              }
            ]
          },
          {
            title: 'I. KOMENTAR PADA PLATFORM KAMI',
            body1: [
              'Dalam Platform Kami Anda dapat menggunakan fitur komentar yang dapat di unggah kedalam Platform Kami. Komentar yang dilarang adalah sebagai berikut:',
              {
                body2: [
                  'Dapat menipu seseorang;',
                  'Dibuat dengan melanggar kewajiban hukum-apa pun kepada pihak ketiga, seperti kewajiban kontrak atau kewajiban menjaga kerahasiaan;',
                  'Dapat melecehkan, menyinggung, mempermalukan, membuat khawatir, atau mengganggu siapa pun;',
                  'Digunakan untuk menyamar sebagai orang lain, atau untuk memalsukan identitas atau afiliasi Anda dengan orang lain;',
                  'Mengandung materi yang tidak senonoh, menyinggung, bersifat kebencian, menghasut, atau mencermarkan nama baik seseorang;',
                  'Mempromosikan akses terhadap zat-zat terlarang, narkotika, rokok, materi tambahan tembakau, perdagangan manusia dan organ tubuh;',
                  'Mempromosikan diskriminasi berdasarkan ras, jenis kelamin, agama, kebangsaan, kecacatan, orientasi seksual, atau usia; ',
                  'Mempromosikan kekerasan dan materi yang eksplisit secara seksual;',
                  'Mempromosikan layanan meretas (hacking) dan/atau membobol (retak);',
                  'Mempromosikan akses terhadap produk penipuan, pencucian uang, skema pemasaran berjenjang (multi-level marketing), serta produk bajakan;',
                  'Mempromosikan penjualan tidak sah terhadap produk-produk yang membutuhkan lisensi;',
                  'Melanggar hak cipta, hak pangkalan data (database right), atau pun merek dagang orang lain;',
                  'Mengancam, menyalahgunakan, atau menyerang privasi orang lain, atau menyebabkan gangguan, ketidaknyamanan, maupun kecemasan yang tidak perlu;',
                  'Memberi kesan bahwa Konten tersebut berasal dari Kami, ketika tidak benar demikian;',
                  'Mengadvokasi, mempromosikan, atau membantu tindakan yang melanggar hukum seperti (hanya sebagai contoh) pelanggaran hak cipta atau penyalahgunaan komputer;',
                  'Mengandung atau berhubungan dengan materi dan/atau konten kelas yang dibeli; dan',
                  'Mempromosikan aktivitas melanggar hukum apa pun.'
                ]
              },
              'Anda menjamin bahwa komentar Anda Akurat, benar-benar dimaksudkan, mematuhi hukum yang belaku dan tidak membuat komentar yang telah disebutkan diatas. Anda akan bertanggung jawab untuk segala kerugian atau kerusakan yang Kami derita akibat pelanggaran jaminan Anda.',
              'Komentar yang Anda unggah ke Platform Kami akan dianggap bersifat tidak rahasia, tidak bersifat kepemilikan dan tidak melanggar hak kekayaan intelektual dari pihak manapun, kecuali Anda nyatakan sebaliknya kepada Kami sebagaimana dijelaskan dalam Kebijakan Privasi Platform Kami. Anda tetap memiliki semua hak kepemilikan terhadap komentar Anda, namun Anda diharuskan untuk memberitahukan Kami dan pengguna Platform Kami lainnya atas lisensi terbatas untuk menggunakan, menyimpan, dan menyalin komentar tersebut, serta untuk mendistribusikan dan membuatnya tersedia bagi pihak ketiga. ',
              'Kami tidak bertanggung jawab atau dikenakan kewajiban oleh pihak ketiga mana pun, untuk komentar atau akurasi dari komentar mana pun yang Anda pampang atau pengguna Platform Kami lainnya. ',
              'Kami berhak menghapus setiap komentar yang Anda buat pada Platform Kami, jika menurut pendapat Kami, komentar Anda tidak sesuai dengan standar komentar yang ditetapkan dalam Syarat & Ketentuan Kami. ',
              'Kami juga berhak menyingkap identitas Anda kepada pihak ketiga mana pun yang mengklaim bahwa komentar yang Anda pampang atau unggah merupakan pelanggaran terhadap hak kekayaan intelektual mereka, atau hak privasi mereka.'
            ]
          },
          {
            title: 'J. LAPORAN PELANGGARAN',
            body1: [
              'Jika Anda menemukan konten apa pun pada Platform Kami yang Anda yakini melanggar hak cipta apa pun, menyalahi hak lainnya, merusak nama baik, bersifat fitnah, kebohongan, kecabulan, pornografi, tidak senonoh, rasis, atau dengan cara-cara lain menyebabkan pelanggaran secara luas, atau yang merupakan peniruan identitas, penyalahgunaan, spam, atau sebaliknya menyalahi Persyaratan Penggunaan serta Kebijakan Privasi maupun peraturan yang berlaku lainnya, mohon untuk dilaporkan kepada Kami melalui detail kontak yang tercantum pada bagian Syarat & Ketentuan ini. Pastikan bahwa Anda menyertakan dalam laporan tersebut ("Laporan") informasi sebagai berikut: ',
              {
                body2: [
                  'pernyataan bahwa Anda telah mengidentifikasi konten yang melanggar atau menyalahi Syarat & Ketentuan dan Kebijakan Privasi Platform Kami maupun peraturan yang berlaku lainnya pada Platform Kami;',
                  'screenshot dari konten yang melanggar atau bersifat ilegal;',
                  'deskripsi dari pelanggaran atau konten ilegal serta tautan di mana konten tersebut berada; dan',
                  'nama lengkap, alamat dan nomor telepon Anda, alamat surat elektronik di mana Anda dapat dihubungi, serta nama pengguna (username) Akun Anda jika Anda memilikinya.'
                ]
              },
              'Dengan membuat Laporan, Anda akan dianggap telah menyertakan, dalam Laporan tersebut:',
              {
                body2: [
                  'pernyatan bahwa informasi dalam Laporan tersebut adalah akurat dan Anda dengan niatan baik percaya bahwa penggunaan materi yang disengketakan tersebut tidak diizinkan oleh pemilik hak cipta, agennya, maupun hukum; dan',
                  'pada kasus di mana Anda melaporkan konten yang Anda yakini melanggar hak cipta atau hak-hak terkait lainnya, pernyataan bahwa Anda berwenang untuk bertindak atas nama pemilik hak cipta atau hak-hak lain yang diduga telah dilanggar.'
                ]
              }
            ]
          },
          {
            title: 'K. KEKAYAAN INTELEKTUAL',
            body1: [
              'Platform Kami, termasuk namun tidak terbatas pada, nama, logo, kode program, desain, merek dagang, teknologi, basis data, proses dan model bisnis, konten-konten dan materi-materi pada kelas dilindungi oleh hak cipta, merek, paten dan hak kekayaan intelektual lainnya yang tersedia berdasarkan hukum Republik Indonesia yang terdaftar atas nama Kami. Kami memiliki seluruh hak dan kepentingan atas Platform, termasuk seluruh hak kekayaan intelektual terkait dengan seluruh fitur yang terdapat didalamnya dan hak kekayaan intelektual terkait atas kepemilikan terhadap Hak Kekayaan Intelektual (HAKI) dalam atau atas Platform Kami serta atas setiap Materi yang dimiliki atau berlisensi atau dikuasai oleh Kami atau pemberi lisensi Kami atau penyedia layanan Kami, Kami berhak untuk menjaga Kekayaan Intelektual sepenuhnya berdasarkan ketentuan hukum yang berlaku di Republik Indonesia.',
              'Anda tidak boleh mengubah salinan dalam bentuk kertas maupun digital dari materi apa pun yang telah Anda cetak atau unduh dengan cara apa pun, dan Anda tidak boleh menggunakan ilustrasi, foto-foto, potongan video atau audio, maupun grafis lain secara terpisah dari teks pendampingnya. Tidak ada bagian-bagian dari Platform yang dapat direproduksi, direkayasa, dikompilasi, dibongkar, dipisahkan, diubah, didistribusikan, penerbitan ulang, ditampilkan, disiarkan, ditautkan, direfleksikan (mirrored), disusun (framed), ditransfer atau dikirim dengan cara apapun atau disimpan/dipasang dalam suatu sistem pencarian informasi atau dipasang pada suatu server, sistem atau peralatan, tanpa izin tertulis sebelumnya dari Kami atau dari pemilik hak cipta yang bersangkutan. Pemberian izin hanya akan diberikan kepada Anda untuk mendownload, mencetak atau menggunakan Materi untuk penggunaan pribadi dan non-komersial, dengan ketentuan Anda tidak mengubah dari Materi yang ada, Kami atau pemilik Hak Cipta yang bersangkutan adalah pemegang semua/tiap Hak Cipta dan Hak Cipta kepemilikan lainnya yang terkandung pada Materi.',
              'Anda dilarang untuk:',
              {
                body2: [
                  'menyalin, mengubah, mencetak, mengadaptasi, menerjemahkan, menciptakan karya tiruan dari, mendistribusikan, memberi lisensi, menjual, memindahkan, menampilkan secara publik, menunjukkan secara publik, menggandakan, mengirimkan, menyiarkan lewat media online maupun offline, memotong, membongkar, atau sebaliknya mengeksploitasi bagian mana pun dari Platform Kami, termasuk namun tidak terbatas pada konten-konten dan materi-materi berbayar pada Platform, baik secara fisik maupun digital, dalam masa berlangganan maupun selesai berlangganan Platform;',
                  'memberi lisensi, lisensi turunan, menjual, menjual ulang, memindahkan, menetapkan, mendistribusikan, atau sebaliknya mengeksploitasi atau membagikan secara komersial Platform Kami dan/atau perangkat lunak lain yang terasosiasi dengan Platform Kami dua dengan cara apa pun; dan',
                  'merekayasa balik atau mengakses Platform Kami guna membangun produk atau jasa yang kompetitif, menyalin dan membangun produk berdasarkan fitur, ide, fungi, maupun grafis yang serupa dengan Platform Kami.'
                ]
              },
              'Tidak ada pada Platform dan Syarat Penggunaan ini yang bisa ditafsirkan sebagai pemberian, secara tersirat atau tidak, atau di tafsirkan sebagai lisensi atau hak untuk menggunakannya (termasuk atas meta tag atau "hot" link ke website lain) setiap merek dagang  yang ditampilkan pada Layanan harus dengan izin tertulis dari Kami atau pemilik merek dagang lainnya.'
            ]
          },
          {
            title: 'L. TINDAKAN YANG KAMI ANGGAP PERLU',
            body1: [
              'Apabila Anda telah melanggar dan/atau melakukan tindakan asusila, pelanggaran, kejahatan atau tindakan lain yang bertentangan dengan Syarat & Ketentuan ini dan/atau peraturan perundang-undangan yang berlaku, maka Kami berhak untuk dan dapat membekukan Akun, baik sementara atau permanen, atau memblokir Anda untuk dapat mengakses Platform, serta melakukan, menuntut ganti kerugian, melaporkan aktivitas ke pihak yang berwajib berdasarkan peraturan dan perundangan yang berlaku dan/atau mengambil tindakan lain yang kami anggap perlu, termasuk namun tidak terbatas kepada Tindakan berdasarkan hukum pidana maupun perdata.',
              'Kami akan menindaklanjuti dengan melakukan investigasi dan/atau memfasilitasi Penyedia Layanan yang bersangkutan atau pihak ketiga atau pihak lainnya yang berdampak atau terdampak untuk melaporkan kepada pihak yang berwajib apabila Kami menerima Laporan adanya pelanggaran yang Anda lakukan atas Syarat & Ketentuan ini ataupun pelanggaran terhadap peraturan perundang-undangan yang berlaku, sehubungan dengan pelecehan atau kekerasan verbal, termasuk namun tidak terbatas kepada fisik, jenis kelamin, suku, agama dan ras. '
            ]
          },
          {
            title: 'M. MEMBUAT TAUTAN KE PLATFORM KAMI',
            body1: [
              'Anda dapat membuat tautan ke Platform Kami, sepanjang Anda melakukannya dengan cara yang adil dan sesuai dengan peraturan dan perundangan yang berlaku serta tidak merusak reputasi Kami atau mengambil keuntungan darinya. Anda tidak diperbolehkan membuat tautan sedemikian rupa sehingga memberi kesan adanya suatu asosiasi, persetujuan, ataupun dukungan dari Kami ketika hal tersebut tidak benar adanya. Anda tidak diperbolehkan membuat tautan ke Platform Kami pada situs web mana pun yang tidak dimiliki oleh Anda. Kami berhak mencabut dan/atau menghapus atas izin dan/atau pembuatan tautan tersebut tanpa pemberitahuan lebih dulu. Situs web yang Anda berikan tautan harus mematuhi segala aspek standar konten yang telah ditetapkan melalui Syarat & Ketentuan Kami. Jika Anda ingin menggunakan konten pada Platform Kami selain melalui hal-hal yang diatur diatas, dapat menghubungi Kami melalui detail kontak yang tercantum pada bagian Syarat & Ketentuan ini.'
            ]
          },
          {
            title: 'N. TAUTAN PIHAK KETIGA',
            body1: [
              'Di mana Platform Kami mengandung tautan dan akses ke situs web dan konten lain yang disediakan oleh pihak ketiga atau pihak lainnya. Kami tidak memiliki control atas konten situs web atau sumber daya tersebut. Kami tidak bertanggung jawab atas bagian apapun dari isi konten yang disediakan oleh pihak ketiga atau pihak lainnya tersebut. Akses atau penggunaan Anda terhadap konten pihak ketiga atau pihak lainnya tersebut merupakan bentuk persetujuan Anda untuk tunduk pada Syarat & Ketentuan dan Kebijakan Privasi yang ditetapkan oleh pihak ketiga atau pihak lainnya tersebut'
            ]
          },
          {
            title: 'O. TANGGUNG JAWAB ANDA',
            body1: [
              'Anda bertanggung jawab penuh atas keputusan yang Anda buat untuk menggunakan atau mengakses Platform. Anda bertanggung jawab secara penuh atas setiap kerugian dan/atau klaim yang timbul dari penggunaan Platform melalui Akun Anda, baik oleh Anda atau pihak lain yang menggunakan Akun Anda, dengan cara yang bertentangan dengan Syarat & Ketentuan ini, Kebijakan Privasi, atau peraturan dan perundang-undangan yang berlaku, termasuk namun tidak terbatas untuk tujuan pencucian uang, pendanaan atau penghasutan untuk melakukan tindakan terorisme, aktivitas kejahatan, penipuan dalam bentuk  apapun (termasuk namun tidak terbatas pada kegiatan phishing dan/atau social engineering), pelanggaran hak kekayaan intelektual, dan/atau aktivitas lain yang merugikan publik dan/atau pihak lain mana pun atau yang dapat atau dianggap dapat merusak reputasi Kami.  Setiap risiko kesalahpahaman, kesalahan, kerusakan, biaya atau kerugian yang diakibatkan dari penggunaan Platform, adalah sepenuhnya risiko dan tanggung jawab Anda sendiri dan Kami tidak bertanggung jawab untuk itu. Anda bertanggung jawab untuk mengatur program komputer, teknologi informasi, maupun platform yang Anda gunakan untuk mengakses Platform Kami, karena Kami tidak menjamin Platform Kami akan selalu aman atau terbebas dari bug atau virus atau bentuk gangguan apapun, Anda harus menggunakan perangkat lunak anti virus Anda Sendiri.'
            ]
          },
          {
            title: 'P. BATASAN TANGGUNG JAWAB KAMI',
            body1: [
              'Platform yang disediakan oleh Kami adalah sebagaimana adanya dan sebagaimana tersedia. Kami tidak menyatakan atau menjamin bahwa:',
              {
                body2: [
                  'ketepatan waktu, kesesuaian, akurasi, ketersediaan, kelengkapan, kualitas atau keamanan dari Platform dapat memenuhi kebutuhan dan akan sesuai dengan yang Anda inginkan;',
                  'bahwa Platform selalu tersedia tanpa gangguan, aman atau bebas dari kesalahan atau kelalaian, atau setiap cacat yang ditemukan akan langsung diperbaiki;',
                  'bahwa Platform selalu bebas dari virus komputer atau kode berbahaya lainnya, merugikan, merusak, agen, program atau macro; dan',
                  'keamanan atas informasi apapun yang dikirim oleh Anda melalui Platform dan dengan ini Anda menerima risiko bahwa informasi apapun yang dikirim atau diterima melalui Platform dapat diakses oleh pihak ketiga yang tidak sah dan/atau diungkapkan oleh Kami atau petugas, karyawan atau agen kepada pihak ketiga yang mengaku Anda atau mengaku bertindak di bawah otoritas Anda. Transmisi melalui Internet dan surat elektronik dapat dikenakan gangguan, transmisi pemadaman, tertunda transmisi karena lalu lintas internet atau transmisi data yang tidak benar karena sifat publik dari internet.'
                ]
              },
              'Kami tidak bertanggung jawab kepada Anda untuk Kerugian apapun atau apapun penyebabnya (dalam bentuk apapun) yang timbul secara langsung atau tidak langsung yang terkait atas:',
              {
                body2: [
                  'akses, penggunaan dan/atau ketidakmampuan untuk menggunakan Platform;',
                  'konten dari pihak ketiga terkait mitra, partner dan/atau pihak-pihak lainnya  yang terdapat dalam Platform.',
                  'ketergantungan Anda pada data atau informasi yang tersedia melalui Platform. Anda tidak seharusnya bertindak hanya mengandalkan data atau informasi tanpa terlebih dahulu secara independen/mandiri memverifikasi isinya;',
                  'sistem, server atau koneksi yang gagal, kesalahan, kelalaian, gangguan, keterlambatan dalam transmisi, virus komputer atau kode berbahaya, merugikan, merusak lainnya agent program atau macros;',
                  'setiap penggunaan atau akses ke website lain atau halaman web yang ter-link dengan Platform atau terdapat linknya di Platform, walaupun jika Kami atau petugas Kami atau agen atau karyawan Kami telah diberitahukan, atau mungkin telah diantisipasi, atas kemungkinan yang sama; dan',
                  'atas konten situs web yang terhubung dengan Platform Kami. Tautan semacam itu seharusnya tidak ditafsirkan sebagai bentuk dukungan Kami terhadap situs-situs tersebut. Kami tidak bertanggung jawab atas kerugian atau kerusakan apa pun yang timbul dari penggunaan Anda terhadap situs-situs web tersebut.'
                ]
              },
              'Kami hanya menyediakan Platform untuk penggunaan domestik dan pribadi. Anda setuju untuk tidak menggunakan Platform Kami untuk tujuan komersial atau bisnis apa pun, dan Kami tidak bertanggung jawab kepada Anda atas kerugian, kehilangan usaha, gangguan usaha, maupun hilangnya kesempatan bisnis. ',
              'Kami tidak berkewajiban untuk mengawasi akses atau penggunaan Anda terhadap Platform. Akan tetapi, Kami akan tetap melakukan pengawasan untuk memastikan penggunaan Platform dan memastikan kepatuhan Anda terhadap Syarat & Ketentuan ini, peraturan peraturan yang berlaku, putusan pengadilan, dan/atau ketentuan peraturan administratif atau badan lainnya. '
            ]
          },
          {
            title: 'Q. Keadaan Memaksa (Force Majeure)',
            body1: [
              'Platform Kami dapat diganggu oleh kejadian di luar kewenangan atau kendali Kami (“Keadaan Memaksa”), termasuk namun tidak terbatas pada bencana alam (gempa bumi, tsunami, gunung meletus, banjir, kekeringan dan tanah longsor), wabah penyakit, kebakaran, sengketa industrial, pemogokan massal, perang saudara, pemberontakan, perebutan kekuasaan, perang dengan negara lain, terorisme, dan/atau tidak berfungsinya jaringan dan/atau komputer yang disebabkan karena tenaga listrik umum tidak berfungsi atau oleh sebab lain di luar kekuasaan Kami. Anda setuju untuk membebaskan Kami dari setiap tuntutan dan pertanggungjawaban, jika Kami tidak dapat memfasilitasi Layanan, termasuk memenuhi instruksi yang Anda berikan melalui Platform, jika hal tersebut secara keseluruhan atau sebagian, langsung atau tidak langsung, oleh suatu peristiwa atau kegagalan yang disebabkan Keadaan Memaksa.'
            ]
          },
          {
            title: 'R. Ketentuan Lainnya',
            body1: [
              'Anda mengerti dan setuju bahwa Syarat & Ketentuan ini merupakan perjanjian dalam bentuk elektronik. Tindakan Anda mendaftar pada saat pembuatan akun atau masuk (login) saat akan mengakses Akun Anda merupakan persetujuan aktif Anda untuk mengikatkan diri dalam perjanjian dengan Kami, sehingga keberlakuan Syarat & Ketentuan ini dan Kebijakan Privasi adalah sah dan mengikat secara hukum dan terus berlaku sepanjang penggunaan Platform oleh Anda.',
              'Kami dapat merubah Syarat & Ketentuan ini dengan pemberitahuan melalui Platform atau dengan metode lain seperti yang Kami inginkan (termasuk tetapi tidak terbatas kepada pemberitahuan melalui e-mail).  Anda setuju bahwa Anda bertanggung jawab untuk meninjau Syarat & Ketentuan ini secara berkala, dan Penggunaan secara berkelanjutan oleh Anda akan dianggap sebagai persetujuan dan penerimaan Anda atas perubahan tersebut. Jika Anda menolak atas perubahan tersebut, Anda harus berhenti akses atau menggunakan Platform dan mengakhiri Syarat & Ketentuan Penggunaan ini. Hak Kami untuk merubah Syarat & Ketentuan Penggunaan di tersebut diatas dapat dilaksanakan tanpa persetujuan dari setiap orang atau badan yang bukan merupakan pihak berdasarkan Syarat & Ketentuan Penggunaan ini.',
              'Anda tidak akan mengajukan tuntutan atau keberatan apapun terhadap keabsahan dari Syarat & Ketentuan atau Kebijakan Privasi yang dibuat dalam bentuk elektronik.',
              'Mata Uang yang berkaitan dengan Syarat & Ketentuan Penggunaan ini adalah dalam mata uang Rupiah Indonesia.',
              'Setiap kesalahan ketik, administrasi atau kesalahan lainnya atau kelalaian dalam penerimaan, faktur atau dokumen lainnya yang terjadi pada sisi Kami akan menjadi bahan perbaikan Kami yang dilakukan oleh Kami.',
              'Kami berhak untuk mendelegasikan atau sub-kontrak kinerja dari setiap fungsi Kami  sehubungan dengan Platform dan berhak untuk menggunakan penyedia layanan, subkontraktor dan/atau agen pada seperti hal sebagaimana Kami anggap pantas.',
              'Anda tidak dapat mengalihkan hak dan kewajiban Anda atas Syarat & Ketentuan Penggunaan tanpa izin tertulis sebelumnya dari Kami.',
              'Kegagalan Kami untuk menegakkan Syarat & Ketentuan Penggunaan tidak dapat diartikan sebagai diabaikannya dari ketentuan-ketentuan ini, dan kegagalan tersebut tidak akan mempengaruhi hak Kami selanjutnya untuk menegakkan Syarat & Ketentuan Penggunaan ini. Kami akan tetap berhak menggunakan hak dan remedy/upaya hukum Kami dalam setiap kondisi lain di mana Anda melanggar Syarat & Ketentuan Penggunaan ini.',
              'Kami dapat memperbaharui Platform dari waktu ke waktu serta mengubah kontennya kapan pun. Meskipun demikian, perlu diketahui bahwa Platform Kami dapat memiliki konten yang tidak diperbarui pada waktu tertentu, dan Kami tidak berkewajiban untuk memperbaruinya.',
              'Kami akan senantiasa berupaya untuk memastikan, namun tidak sepenuhnya menjamin, bahwa Aplikasi Kami beserta konten yang terkandung di dalamnya dapat bebas sepenuhnya dari kesalahan atau kelalaian.',
              'Syarat & Ketentuan ini tetap berlaku bahkan setelah pembekuan sementara, pembekuan permanen, penghapusan Aplikasi atau setelah berakhirnya perjanjian ini antara Anda dan Kami.',
              'Jika suatu saat ada ketentuan pada Syarat & Ketentuan Penggunaan ini yang menjadi ilegal, tidak sah atau tidak dapat diterapkan dalam hal apapun, maka legalitas, validitas dan keberlakuan dari ketentuan lainnya dalam Syarat & Ketentuan Penggunaan ini tidak akan terpengaruh atau berkurang karenanya, dan akan terus berlaku.',
              'Kami membuat Syarat & Ketentuan ini  dalam bahasa Indonesia dan Inggris, dan dalam hal terdapat setiap pertentangan antara versi bahasa Inggris dan Indonesia, maka versi bahasa Indonesia yang akan berlaku.',
              'Syarat & Ketentuan Penggunaan ini diatur berdasarkan hukum Republik Indonesia dan pada yurisdiksi eksklusif Pengadilan Negeri Jakarta Barat.'
            ]
          },
          {
            title: 'S. Cara Menghubungi Kami',
            body1: [
              'Jika Anda memiliki pertanyaan seputar Syarat & Ketentuan ini, Anda dapat menghubungi Kami melalui legal@g2academy.co.',
              'Saya telah membaca dan mengerti seluruh Syarat & Ketentuan ini dan konsekuensinya dan dengan ini menerima setiap hak, kewajiban, dan ketentuan yang diatur di dalamnya.'
            ]
          }
        ]
      },
      {
        staticText: {
          title: 'TERMS & CONDITIONS'
        },
        selections: {
          selectLang: 'Select Language'
        },
        sideBarComp: ['Privacy Policy', 'Terms & Conditions'],
        backToHome: 'Back to Home',
        loopingText: [
          {
            title: 'A. GENERAL PROVISIONS',
            body1: [
              'These Terms & Conditions are an agreement between the user ("You") and PT Generasi Teknologi Buana ("We”), a limited liability company which is legally established and operates under the laws of the Republic of Indonesia and domiciled in DKI Jakarta. These Terms & Conditions are to regulate you when accessing and using applications, websites that we manage, features, technology, content and products that we provide (“Platform”), including but not limited to all derivatives and/or related or related one with others over our applications, websites, features, technology, content and products.',
              'By agreed to these Terms & Conditions, you also agree to the additional Terms & Conditions, and their amendments or derivatives and/or relating to or relating to Us which are an integral part of these Terms & Conditions ("Terms & Conditions").',
              'You represent and warrant that you are at least 18 (eighteen) years old or married and are not under guardianship or custody and that you legally have the capacity and the right to bind yourself to these Terms & Conditions. If you do not meet these conditions, then your act of registering, accessing, using, or carrying out other activities in the Application and/or Services that we provide must be with the knowledge, supervision and legal consent of your parents, guardians or benefactor. Parents, guardians or benefactor who give consent for you who are under 18 (eighteen) years old are fully responsible for all your actions and activities in the use of and access to our Platform.',
              'You expressly waive any legal right to cancel or revoke any and all consents that you have given under these Terms & Conditions when you are considered legally mature.',
              'By registering and/or using Our Application, you and/or your parents, guardians or benefactor (if you are under 18 years old) are deemed to have read, understood, understood, and agreed to all of the contents in these Terms & Conditions. If you do not agree to any, part, or all of these Terms & Conditions, then please stop your access and use of our Platform immediately.'
            ]
          },
          {
            title: 'B. CREATE ACCOUNT',
            body1: [
              'Before you use our Platform, you are deemed to agree to these Terms & Conditions and Privacy Policy, and register yourself on our Platform by providing the information we need. When registering we will ask you to provide your full name, telephone number and electronic mail, if we know the data provided is proven to be incorrect then we can stop you from using our platform.',
              'With the data you have provided, our system will create an account on the Platform ("account") that is used for using the platform and ordering services provided on the Platform. When you log out of your account, you need to enter the electronic mail address that you provided to us during registration and enter a password.',
              'The account may not be transferred for any reason to another person, it can only be used by you. If we know that the account has been transferred or used by someone else, we have the right to refuse to facilitate the service.',
              'The security and confidentiality of your account is entirely your personal responsibility. All losses and risks arising from your negligence in maintaining the security and confidentiality as stated are borne by you. Accordingly, We will consider any use or order made through your account as a valid request from you.',
              'You please notify us immediately if you know or suspect that your account has been used without your knowledge and consent. We will take actions that We deem necessary and We can take against such use without consent.',
              'If you already have an account on our platform, then that account will be managed by us and G2Academy (Affiliated) and the account can be used on the G2Academy Platform (linked accounts).',
              '<b>G2Academy</b> is a partner or affiliate of Eduverse that cooperates with each other, where G2Academy is the official digital platform of the Pre-Employment Card program that provides online and offline course purchase services.'
            ]
          },
          {
            title: 'C. PERSONAL INFORMATION',
            body1: [
              'The collection, use, storage, processing and sharing of your Personal Information or personal data, such as your name, address, telephone number and electronic mail, which you provide when you create an account is subject to the Privacy Policy, which is an integral part of the Terms & Conditions.'
            ]
          },
          {
            title: 'D. ACCESS PLATFORM',
            body1: [
              'We do not guarantee that Our Platform, nor the content on it, will always be available or uninterrupted. Permission to access our platform is temporary. We may suspend, withdraw, terminate or replace any part of Our Platform without prior notice. We are not responsible for any reason that makes our platform unavailable at certain times or periods and from time to time. You are responsible for making all necessary arrangements to gain access to Our Platform.'
            ]
          },
          {
            title: 'E. PURCHASE AND ACCESS RIGHTS TO THE CLASS ON THE PLATFORM',
            body1: [
              'When you make a purchase of training classes on our platform, we grant you a non-exclusive, limited and non-transferable license to access and view materials and content related to classes that you have paid for. Class materials and content obtained after you purchase a training class are for personal use only and all use for other purposes is expressly prohibited (such as commercial purposes).',
              'You may not record, redistribute, sell, transmit, assign, broadcast, reproduce, share, lend, modify, adapt, edit, sublicense, create derivative works, or otherwise transfer or use the materials and content on the Platform except with the prior written consent of We.',
              'We grant you access licenses when purchasing classes on our Platform as long as this Platform is still operational for you. However, we reserve the right to revoke any license to access and use classes at any time in the event that we decide or are obliged to disable access to classes for certain reasons (such as intellectual property infringement) and the training classes that you have purchased through our Platform cannot be canceled or refunded.'
            ]
          },
          {
            title: 'F. CERTIFICATE',
            body1: [
              'We will issue a certificate on Our Platform and to get a Certificate you must complete the entire series of obligations (video) and must take the final exam and pass the exam above the predetermined passing grade.',
              'The certificate is a form of appreciation that we give if you follow the entire series of training thoroughly and as a sign that you have mastered the material given in the class.'
            ]
          },
          {
            title: 'G. PAYMENT TERMS',
            body1: [
              'When you make a payment for classes on our Platform, you agree to:',
              {
                body2: ['Use legal means of payment using the methods available on the Platform; and', 'pay the purchase price for access to the classes you purchase.']
              },
              'You authorize us to process the sale and purchase transaction for the class that you purchased with a debit card, credit card or other means of payment that you used at the time of purchasing the class on our Platform.',
              'We work closely with third parties regarding payment processing to offer you the most convenient payment method and to keep your payment information secure. When you make a purchase, you agree not to use any invalid or unauthorized payment methods. If your payment method fails, please notify us immediately via the contact details listed in this Terms & Conditions section. We reserve the right to disable your access to the class you purchased if we have not received payment for the class you purchased.'
            ]
          },
          {
            title: 'H. PROHIBITED USE',
            body1: [
              'Our platform can be used for purposes permitted by applicable laws and regulations. You are prohibited from using Our Platform if:',
              {
                body2: [
                  'violate applicable local, national and international laws and regulations;',
                  'is deceptive, or has an unlawful or deceptive purpose or effect;',
                  'the purpose of harming or attempting to harm a minor in any way;',
                  'send, knowingly receive, upload, download, use, or reuse material that is not in accordance with our content standards;',
                  'access without permission, interfere with, damage, or interfere with anything related to Our Platform;',
                  'distribute or transmit advertising or promotional materials that are not. unwanted or unauthorized, and other forms of similar requests (such as spam); and',
                  'knowingly forwarding data, sending or uploading material containing viruses, trojans, worms, logic bombs, keystroke loggers, spyware, adware, malware or other malicious programs or similar computer code designed to have a detrimental effect on the operation of any software or hardware even.'
                ]
              }
            ]
          },
          {
            title: 'I. COMMENT ON OUR PLATFORM',
            body1: [
              'On our platform you can use the comments feature that can be uploaded to our Platform. The prohibited comments are as follows:',
              {
                body2: [
                  'Can deceive someone;',
                  'Created in violation of any legal obligations to third parties, such as contractual obligations or confidentiality obligations;',
                  'Can harass, offend, embarrass, worry or annoy anyone; ',
                  'Used to impersonate another person, or to fake your identity or affiliation with another person;',
                  'Contains material that is obscene, offensive, hateful, incites or defame someone;',
                  'Promote access to illegal substances, narcotics, cigarettes, tobacco mining materials, trafficking in people and organs;',
                  'Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age;',
                  'Promote violence and sexually explicit material;',
                  'Promote hacking and/or breaking (cracking) services;',
                  'Promote access to fraudulent products, money laundering, multi-level marketing schemes, and pirated products;',
                  'Promote the unauthorized sale of products that require a license;',
                  'Violate copyright, database rights, or trademarks of others;',
                  'Threatens, abuses, or invades the privacy of others, or causes unnecessary annoyance, inconvenience or anxiety;',
                  'Give the impression that the Content is from Us, when this is not true;',
                  'Advocating, promoting or assisting unlawful acts such as (as examples only) copyright infringement or computer abuse;',
                  'Contains or relates to purchased class materials and/or content; and',
                  'Promote any unlawful activity.'
                ]
              },
              'You warrant that your comments are accurate, truly intended, comply with applicable laws and do not make any of the comments mentioned above. You will be responsible for any loss or damage that We suffer as a result of your breach of warranty.',
              "Comments that you upload to our Platform will be considered non-confidential, non-proprietary and do not violate the intellectual property rights of any party, unless you state otherwise to us as described in our Platform's Privacy Policy. You retain all ownership rights to your comments, but you are required to notify us and other users of our platform of a limited license to use, store and copy such comments, as well as to distribute and make them available to third parties.",
              'We are not responsible or liable by any third party, for the comments or accuracy of any comments posted by you or other users of our Platform.',
              'We reserve the right to delete any comments you make on our Platform, if in our opinion, your comments do not meet the comment standards set out in our Terms & Conditions.',
              'We also reserve the right to disclose your identity to any third party who claims that a comment you post or upload is a violation of their intellectual property rights, or their right to privacy.'
            ]
          },
          {
            title: 'J. VIOLATION REPORT',
            body1: [
              'If you find any content on Our Platform that you believe infringes any copyright, violates any other rights, is defamatory, libellous, lies, obscene, pornographic, obscene, racist, or in any other way causes widespread infringement, or which constitutes impersonation, abuse, spam, or otherwise violates the Terms of Use and Privacy Policy or other applicable regulations, please report it to us via the contact details listed in this Terms & Conditions section. Please ensure you include it in the report ("Report") the following information:',
              {
                body2: [
                  "a statement that you have identified content that violates or violates our Platform's Terms & Conditions and Privacy Policy as well as other applicable regulations on Our Platform;",
                  'screenshots of infringing or illegal content;',
                  'a description of the infringement or illegal content and a link where the content is located; and',
                  'your full name, address and telephone number, e-mail address where you can be contacted, and your Account username if you have one.'
                ]
              },
              'By creating a Report, you will be deemed to have included, in the Report:',
              {
                body2: [
                  'a statement that the information in the Report is accurate and you have a good faith belief that use of the disputed material is not authorized by the copyright owner, its agent or the law; and',
                  'in cases where you report content that you believe violates copyright or other related rights, a statement that you are authorized to act on behalf of the owner of the copyright or other rights that are alleged to have been infringed.'
                ]
              }
            ]
          },
          {
            title: 'K. INTELLECTUAL PROPERTY',
            body1: [
              'Our Platform, including but not limited to, names, logos, program codes, designs, trademarks, technologies, databases, business processes and models, content and materials in class are protected by copyright, trademarks, patents and other rights. other intellectual property available under the laws of the Republic of Indonesia registered on our behalf. We own all rights and interests on the Platform, including all intellectual property rights related to all the features contained therein and intellectual property rights related to ownership of Intellectual Property Rights (IPR) in or on Our Platform as well as for any Material owned or licensed or controlled by us or our licensors or our service providers, we reserve the right to maintain the intellectual property fully based on the legal provisions in force in the Republic of Indonesia.',
              'You may not modify paper or digital copies of any material that you have printed or downloaded in any way, and you may not use illustrations, photographs, video or audio clippings, or other graphics separately from the accompanying text. No parts of the Platform may be reproduced, engineered, compiled, disassembled, disassembled, modified, distributed, republished, displayed, broadcast, linked, mirrored, framed, transferred or transmitted in any way or stored / installed in an information retrieval system or installed on a server, system or equipment, without prior written permission from us or from the copyright owner concerned. Permission will only be given to you to download, print or use the Material for personal and non-commercial use, provided that you do not change the existing Material, we or the relevant Copyright owner are the holders of all/every Copyright and Copyright ownership. otherwise contained in the Material.',
              'You are prohibited from:',
              {
                body2: [
                  'copy, modify, print, adapt, translate, create copies of, distribute, license, sell, transfer, publicly display, publicly display, reproduce, transmit, broadcast via online or offline media, cut, disassemble, or otherwise exploit any part of Our Platform, including but not limited to paid content and materials on the Platform, both physically and digitally, during the subscription period or after subscription to the Platform;',
                  'license, derivative license, sell, resell, transfer, assign, distribute, or otherwise exploit or share commercially Our Platform and/or other software associated with Our Platform in any way; and',
                  'reverse engineer or access Our Platform to build competitive products or services, copy and build products based on features, ideas, functions, or graphics similar to Our Platform.'
                ]
              },
              'Nothing on the Platform and these Terms of Use should be construed as granting, implied or otherwise, or interpreted as a license or right to use it (including meta tags or "hot" links to other websites) any trademarks displayed on the Service must be with written permission from Us or other trademark owners.'
            ]
          },
          {
            title: 'L. ACTIONS DEEM NECESSARY',
            body1: [
              'If you have violated and/or committed immoral acts, violations, crimes or other actions that are contrary to these Terms & Conditions and/or applicable laws and regulations, then we have the right to and can freeze the account, either temporarily or permanently, or block You can access the Platform, as well as perform, claim compensation, report activities to the authorities based on applicable laws and regulations and/or take other actions that we deem necessary, including but not limited to actions based on criminal or civil law.',
              'We will follow up by conducting an investigation and/or facilitating the Service Provider concerned or a third party or other affected or affected party to report to the authorities if we receive a report of a violation by you of these Terms & Conditions or a violation of the laws and regulations. applicable laws, in connection with verbal harassment or violence, including but not limited to physical, gender, ethnicity, religion and race.'
            ]
          },
          {
            title: 'M. MAKE A LINK TO OUR PLATFORM',
            body1: [
              'You can link to Our Platform, as long as you do so in a fair manner and in accordance with applicable laws and regulations and do not damage our reputation or take advantage of it. You are not allowed to create a link in such a way as to give the impression of an association, approval, or support from us when this is not true. You are not allowed to link to Our Platform on any website that is not owned by you. We reserve the right to revoke and/or delete with permission and/or the creation of the link without prior notice. The website to which you provide a link must comply with all aspects of the content standards that have been established through our Terms & Conditions. If you want to use the content on our platform other than through the things set out above, you can contact us through the contact details listed in this Terms & Conditions section.'
            ]
          },
          {
            title: 'N. THIRD PARTY LINKS',
            body1: [
              'Where Our Platform contains links to and access to websites and other content provided by third parties or other parties. We have no control over the content of these websites or resources. We are not responsible for any part of the content provided by such third parties or other parties. Your access or use of such third party or other party content is a form of your agreement to comply with the Terms & Conditions and Privacy Policy set by the third party or other party.'
            ]
          },
          {
            title: 'O. YOUR RESPONSIBILITY',
            body1: [
              'You are solely responsible for the decisions you make to use or access the Platform. You are fully responsible for any losses and/or claims arising from the use of the Platform through your Account, either by you or by other parties who use your Account, in a manner that is contrary to these Terms & Conditions, Privacy Policy, or laws and regulations. applicable laws, including but not limited to money laundering, financing or incitement to commit acts of terrorism, criminal activity, fraud in any form (including but not limited to phishing and/or social engineering activities), intellectual property rights violations, and/or or other activities that are detrimental to the public and/or any other party or which can or are deemed to damage our reputation. Any risk of misunderstanding, error, damage, cost or loss resulting from the use of the Platform, is entirely at your own risk and responsibility and we are not responsible for it. You are responsible for managing the computer program, information technology, or platform that you use to access our platform, because we do not guarantee that our platform will always be safe or free from bugs or viruses or any form of interference, you must use your own anti-virus software.'
            ]
          },
          {
            title: 'P. LIMITATIONS OF OUR LIABILITY',
            body1: [
              'The Platform provided by us is as is and as available. We do not represent or warrant that:',
              {
                body2: [
                  'the timeliness, suitability, accuracy, availability, completeness, quality or security of the Platform can meet your needs and will be as you wish;',
                  'that the Platform is always available uninterrupted, secure or free from errors or omissions, or any defects found will be corrected immediately;',
                  'that the Platform is always free from computer viruses or other malicious, harmful, destructive code, agents, programs or macros; and',
                  'security of any information sent by you through the Platform and you hereby accept the risk that any information sent or received through the Platform may be accessed by unauthorized third parties and/or disclosed by us or our officers, employees or agents to third parties who claiming to be you or claiming to be acting under your authority. Transmissions over the Internet and electronic mail may be subject to interruption, transmission outage, delayed transmission due to Internet traffic or improper data transmission due to the public nature of the Internet.'
                ]
              },
              'We are not responsible to you for any Loss or any cause (in any form) arising directly or indirectly related to:',
              {
                body2: [
                  'access, use and/or inability to use the Platform;',
                  'content from third parties related to partners, partners and/or other parties contained in the Platform.',
                  'your reliance on data or information available through the Platform. You should not act solely on data or information without first independently verifying its contents;',
                  'system, server or connection failures, errors, omissions, interruptions, delays in transmission, computer viruses or other malicious, harmful, destructive code agent programs or macros;',
                  'any use of or access to other websites or web pages that are linked to the Platform or there are links to them on the Platform, even if we or our officers or our agents or employees have been notified, or may have anticipated, of the same possibility; and',
                  'on the content of websites linked to Our Platform. Such links should not be construed as a form of Our endorsement of these sites. We do not be responsible for any loss or damage arising from your use of those websites'
                ]
              },
              'We only provide the Platform for domestic and personal use. You agree not to use Our Platform for any commercial or business purposes, and We are not responsible to You for any loss, loss of business, business interruption, or loss of business opportunity.',
              'We are under no obligation to monitor your access to or use of the Platform. However, we will continue to supervise to ensure the use of the Platform and ensure your compliance with these Terms & Conditions, applicable regulations, court decisions, and/or the provisions of administrative regulations or other bodies.'
            ]
          },
          {
            title: 'Q. Force Majeure',
            body1: [
              'Our Platform may be disrupted by events beyond our control or control ("Forced Majeure"), including but not limited to natural disasters (earthquakes, tsunamis, volcanic eruptions, floods, droughts and landslides), disease outbreaks, fires, industrial disputes , mass strikes, civil wars, rebellions, power struggles, wars with other countries, terrorism, and/or network and/or computer malfunctions caused by non-functioning public electricity or other reasons beyond our control. You agree to release us from any demands and liability, if we are unable to facilitate the Service, including fulfilling the instructions you give through the Platform, if this is in whole or in part, directly or indirectly, by an event or failure caused by Force Majeure.'
            ]
          },
          {
            title: 'R. Other Terms',
            body1: [
              'You understand and agree that these Terms & Conditions are an electronic agreement. Your act of registering at the time of account creation or logging in when accessing your account is your active agreement to enter into an agreement with us, so that the validity of these Terms & Conditions and the Privacy Policy are valid and legally binding and continue to apply throughout the use of the Platform by you.',
              'We can change these Terms & Conditions by notification through the Platform or by other methods as We wish (including but not limited to notification via e-mail). You agree that it is your responsibility to review these Terms & Conditions periodically, and your continued use will be deemed your acceptance and acceptance of the changes. If you object to such changes, you must stop accessing or using the Platform and terminate these Terms & Conditions of Use. Our right to change the Terms & Conditions of Use mentioned above can be exercised without the consent of any person or entity that is not a party under these Terms & Conditions of Use.',
              'You will not make any claim or objection to the validity of the Terms & Conditions or Privacy Policy made in electronic form.',
              'The currency associated with these Terms & Conditions of Use is in Indonesian Rupiah.',
              'Any typo, administrative or other errors or omissions in receipts, invoices or other documents that occur on our side will be the material for our repairs made by us.',
              'We reserve the right to delegate or sub-contract the performance of any of Our functions in connection with the Platform and have the right to use service providers, subcontractors and/or agents on such matters as We deem appropriate.',
              'You cannot transfer your rights and obligations to the Terms & Conditions of Use without our prior written permission.',
              'Our failure to enforce these Terms & Conditions of Use shall not be construed as a waiver of these terms, and such failure shall not affect Our further right to enforce these Terms & Conditions of Use. We will still be entitled to use our rights and remedies in any other condition where you violate these Terms & Conditions of Use.',
              'We may update the Platform from time to time and change its content at any time. However, please be aware that Our Platform may have content that is not updated at any given time, and We are under no obligation to update it.',
              'We will always strive to ensure, but do not fully guarantee, that Our Application and the content contained in it can be completely free from errors or omissions.',
              'These Terms & Conditions remain in effect even after a temporary suspension, permanent suspension, deletion of the Application or after the expiration of this agreement between You and Us.',
              'If at any time any provision of these Terms & Conditions of Use becomes illegal, invalid or unenforceable in any case, then the legality, validity and enforceability of the other provisions of these Terms & Conditions of Use will not be affected or reduced by it, and will continue to be apply.',
              'We made these Terms & Conditions in Indonesian and English, and in the event of any conflict between the English and Indonesian versions, the Indonesian language version will prevail.',
              'These Terms & Conditions of Use are governed by the laws of the Republic of Indonesia and in the exclusive jurisdiction of the West Jakarta District Court.'
            ]
          },
          {
            title: 'S. How to Contact Us',
            body1: [
              'If you have any questions regarding these Terms & Conditions, you can contact us via legal@g2academy.co.',
              'I have read and understood all of these Terms & Conditions and their consequences and hereby accept every right, obligation and responsibility'
            ]
          }
        ]
      }
    ]
  }),
  computed: {
    ...mapGetters('common', ['contentLanguage'])
  },
  watch: {
    activeIndex: function (value) {
      let name = 'ina'
      value === 0 ? (name = 'ina') : (name = 'en')
      this.setContentLanguage({ id: value, name })
    }
  },
  mounted() {
    this.activeIndex = this.contentLanguage.id
  },
  methods: {
    ...mapMutations('common', ['setContentLanguage'])
  }
}
</script>
<style lang="scss" scoped>
ul.dashed {
  list-style-type: none;
}
ul.dashed > li {
  text-indent: 0px;
  margin-left: 5px;
}
ul.dashed > li:before {
  content: ' - ';
  position: absolute;
  margin-left: -20px;
}
</style>
